import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Radio,
  Segment,
} from "semantic-ui-react";
import "./App.css";

function App() {
  const [rmn, setRmn] = useState("");
  const [sid, setSid] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [theUser, setUser] = useState(null);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setError] = useState("");
  const [dynamicUrl, setDynamicUrl] = useState("");
  const [loginType, setLoginType] = useState("OTP");
  const [pwd, setPwd] = useState("");

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedUserDetails = localStorage.getItem("userDetails");
    if (storedToken && storedUserDetails) {
      setToken(storedToken);
      setUser(JSON.parse(storedUserDetails));
    }
  }, []);

  useEffect(() => {
    if (theUser !== null) {
      if (theUser.acStatus !== " ") {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            slug: "",
            url: `${process.env.REACT_APP_M3U_FUNCTION_BASE_URL}/api/getM3u?sid=${theUser.sid}_${theUser.acStatus[0]}&sname=${theUser.sName}&ent=1000000108_1000000116&tkn=${token}`,
          }),
        };

        fetch(
          "https://tsnehcors.mitthu.workers.dev/?https://tsneh.vercel.app/api/shorten",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            setDynamicUrl(JSON.parse(result).shortUrl);
          })
          .catch((error) => console.log("error", error));
      } else {
        console.log("Something went wrong!");
      }
    } else {
      setDynamicUrl("");
    }
  }, [theUser, token]);

  const getOTP = () => {
    setLoading(true);
    fetch(
      "https://tsnehcors.mitthu.workers.dev/?https://tsneh-tplay-proxy.vercel.app/api/getOtp?sid=" +
        sid +
        "&rmn=" +
        rmn
    )
      .then((response) => response.text())
      .then((result) => {
        const res = JSON.parse(result);
        setLoading(false);
        if (
          res.message.toLowerCase().includes("otp") &&
          res.message.toLowerCase().includes("successfully")
        ) {
          setOtpSent(true);
          setError("");
        } else {
          setError(res.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setError(error.toString());
      });
  };

  const authenticateUser = () => {
    setLoading(true);

    fetch(
      "https://tsnehcors.mitthu.workers.dev/?https://tsneh-tplay-proxy.vercel.app/api/verifyOtp?sid=" +
        sid +
        "&loginType=" +
        loginType +
        "&otp=" +
        otp +
        "&pwd=" +
        pwd +
        "&rmn=" +
        rmn
    )
      .then((response) => response.text())
      .then((result) => {
        const res = JSON.parse(result);
        if (res.code === 0) {
          const userDetails = res.data.userDetails;
          const accessToken = res.data.accessToken;
          setUser(userDetails);
          setToken(accessToken);
          localStorage.setItem("userDetails", JSON.stringify(userDetails));
          localStorage.setItem("token", accessToken);
          setError("");
        } else {
          setError(res.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setError(error.toString());
        setLoading(false);
      });
  };

  const logout = () => {
    localStorage.clear();
    setRmn("");
    setSid("");
    setOtpSent(false);
    setOtp("");
    setPwd("");
    setUser(null);
    setToken("");
    setLoading(false);
  };

  return (
    <div>
      {
        <Grid columns="equal" padded centered>
          {token === "" || theUser === null ? (
            <Grid.Row>
              <Grid.Column></Grid.Column>
              <Grid.Column computer={8} tablet={12} mobile={16}>
                <Segment loading={loading}>
                  <Form>
                    <img
                      alt="TataPlay"
                      height="70"
                      width="260"
                      src={"https://i.ibb.co/3s4SDYb/Tata-Sky-Logo-svg.png"}
                    />
                    <h4>🙋‍♂️ HEY USERS, GENERATE YOUR TATAPLAY PLAYLIST HERE</h4>
                    <h5>
                      ⚓ Kodi Users{" "}
                      <a href="https://tsneh.vercel.app/tataplay-kodi-web">
                        Visit Here
                      </a>
                    </h5>
                    <h5>🕊️ Login with Your TataPlay Accounts ✨ Then Enjoy</h5>
                    <h5>🔔 NOW ONLY OTP LOGIN WORKS 🔔</h5>
                    <Form.Group inline>
                      <label>Login via </label>
                      <Form.Field>
                        <Radio
                          label="OTP"
                          name="loginTypeRadio"
                          value="OTP"
                          checked={loginType === "OTP"}
                          onChange={(e, { value }) => {
                            setLoginType(value);
                          }}
                        />
                      </Form.Field>
                    </Form.Group>
                    {loginType === "OTP" ? (
                      <>
                        <Form.Field disabled={otpSent}>
                          <label>RMN</label>
                          <input
                            value={rmn}
                            placeholder="Registered Mobile Number"
                            onChange={(e) => setRmn(e.currentTarget.value)}
                          />
                        </Form.Field>
                        <Form.Field disabled={otpSent}>
                          <label>Subscriber ID</label>
                          <input
                            value={sid}
                            placeholder="Subscriber ID"
                            onChange={(e) => setSid(e.currentTarget.value)}
                          />
                        </Form.Field>
                        <Form.Field disabled={!otpSent}>
                          <label>OTP</label>
                          <input
                            value={otp}
                            placeholder="OTP"
                            onChange={(e) => setOtp(e.currentTarget.value)}
                          />
                        </Form.Field>
                        {otpSent ? (
                          <Button primary onClick={authenticateUser}>
                            Login
                          </Button>
                        ) : (
                          <Button primary onClick={getOTP}>
                            Get OTP
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        <Form.Field>
                          <label>Subscriber ID</label>
                          <input
                            value={sid}
                            placeholder="Subscriber ID"
                            onChange={(e) => setSid(e.currentTarget.value)}
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>Password</label>
                          <input
                            type="password"
                            value={pwd}
                            placeholder="Password"
                            onChange={(e) => setPwd(e.currentTarget.value)}
                          />
                        </Form.Field>
                        <Button primary onClick={authenticateUser}>
                          Login
                        </Button>
                      </>
                    )}
                  </Form>
                </Segment>
              </Grid.Column>
              <Grid.Column></Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row>
              <Grid.Column></Grid.Column>
              <Grid.Column computer={8} tablet={12} mobile={16}>
                <Segment loading={loading}>
                  <Header as="h1">Welcome, {theUser.sName}</Header>
                  {theUser !== null && theUser.acStatus !== " " ? (
                    <Message>
                      <Message.Header>Dynamic URL to get m3u: </Message.Header>
                      <p>
                        <a href={dynamicUrl}>{dynamicUrl}</a>
                      </p>
                      <p>
                        You can use the above M3U URL in Network Streamer, OTT
                        Navigator or Tivimate App to watch all TataPlay
                        Channels.
                      </p>
                      <p>
                        The generated M3U URL token is valid upto 3 months and
                        is not required to be refreshed every 24 hours. Enjoy!
                      </p>
                      <p>░I░M░P░O░R░T░A░N░T░ ░Q░U░E░R░Y░S░ ░:░</p>
                      <p>
                        If You See Error in above generated URL. Then It's a
                        Token Problem, Just refresh the page for new link to
                        resolve the issue otherwise re-login again with OTP.
                      </p>
                    </Message>
                  ) : (
                    <Header as="h3" style={{ color: "red" }}>
                      Your Tata Sky Connection is Deactivated.
                    </Header>
                  )}

                  <Button negative onClick={logout}>
                    Logout
                  </Button>
                </Segment>
              </Grid.Column>
              <Grid.Column></Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row style={{ display: err === "" ? "none" : "block" }}>
            <Grid.Column></Grid.Column>
            <Grid.Column computer={8} tablet={12} mobile={16}>
              <Message color="red">
                <Message.Header>Error</Message.Header>
                <p>{err}</p>
              </Message>
            </Grid.Column>
            <Grid.Column></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column></Grid.Column>
            <Grid.Column
              textAlign="center"
              computer={8}
              tablet={12}
              mobile={16}
            >
              <a
                href="https://bit.ly/insta-techiesneh"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="TataPlay"
                  height="37"
                  width="100"
                  src={"https://i.ibb.co/xXPfcDf/techiesnehtext.png"}
                />
              </a>
              <Grid.Column></Grid.Column>
              <a
                href="https://bit.ly/insta-techiesneh"
                target="_blank"
                rel="noreferrer"
              >
                TWEAK BY : TSNEH
              </a>
              <Grid.Column></Grid.Column>
              <center>
                <script
                  type="text/javascript"
                  src="//widget.supercounters.com/ssl/online_i.js"
                ></script>
                <script type="text/javascript">
                  sc_online_i(1649960, "ffffff", "2185d0");
                </script>
              </center>
            </Grid.Column>
            <Grid.Column></Grid.Column>
          </Grid.Row>
        </Grid>
      }
      <Modal />
    </div>
  );
}

export default App;
