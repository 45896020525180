import React from "react";
import "./Modal.css";

const Modal = () => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>🚫 Temporarily Disabled</h2>
        {/* <p>Temporarily Disabled</p> */}
      </div>
    </div>
  );
};

export default Modal;
